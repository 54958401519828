import Highway from '@dogstudio/highway';
import {loadCSS, removeCSS} from '../global/global';
import { killInicioAnimations, scrollToInicio, initIndexPage } from '../pages/index/index.js';
import { cursosAnimations, transitionCourseIntro, transitionCourseDescription, killCursosAnimations, scrollToCursos } from '../pages/cursos/cursos.js';
import { killTerapiasANimations, scrollToTherapy, terapiasAnimations } from '../pages/terapias/terapias.js';
import { nosotrosAnimations } from '../pages/nosotros/nosotros.js';
import { contactAnimations, countChar, formValidation, killContactoAnimations, submitForm } from '../pages/contacto/contacto.js';
import gsap from 'gsap/gsap-core';

class BoorpretRenderer extends Highway.Renderer {
    onEnter(){
        let page = this.properties.slug;
        loadCSS(page).then(() => {
            if(page === 'index'){
                initIndexPage();
                scrollToInicio();
            }
            if(page === 'terapias'){
                let therapyActive = new URL(window.location.href).searchParams.get('terapia');
                terapiasAnimations();
                scrollToTherapy(therapyActive);
            }
            if(page === 'cursos'){
                cursosAnimations();
                scrollToCursos();
            }
            if(page === "nosotros"){
                nosotrosAnimations();
            }
            if(page === "contacto"){
                contactAnimations();
                formValidation();
                countChar();
                submitForm();
            }
        }).catch(err => console.error(err));

        //Poner link en activo
        let links = document.querySelectorAll('.fullscreen-menu .link');
        if(document.querySelector('.fullscreen-menu .link.active')){
            document.querySelector('.fullscreen-menu .link.active').classList.remove('active');
        }
        for(let i =0;i<links.length;i++){
            if(links[i].dataset['page'] === page){
                links[i].classList.add('active');
            }
        }
        
    }
    onEnterCompleted(){
        // gsap.to(window, {scrollTo:{y:0}, duration:.2});
    }
    onLeaveCompleted(){
        let page = this.properties.slug;
        //removeCSS(page);
        if(page === 'index'){
            killInicioAnimations();
        }
        if(page === 'terapias'){
            killTerapiasANimations();
        }
        if(page === 'contacto'){
            killContactoAnimations();
        }
        if(page === "cursos"){
            killCursosAnimations();
        }
    }
}

export default BoorpretRenderer;