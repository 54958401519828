/**********************
**---- LIBRERIAS ----**
***********************/
import Highway from '@dogstudio/highway';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import gsap from 'gsap';

/****************
**---- CSS ----**
****************/
import './global.css';


/**************************************
**---- TRANSICIONES ENTRE PAGINAS ----**
****************************************/
import BoorpretRenderer from '../utils/boorpretRenderer';
import Fade from '../utils/transition.js';

/*******************
**---- NAVBAR ----**
********************/
import initNavbar from '../navbar/navbar.js';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

initNavbar();



window.addEventListener('load', () => {    
    document.querySelector('body').style.opacity = 1;
    let logoSvgs = [...document.querySelectorAll('#first-green-screen svg')];

    logoSvgs.map(svg => svg.classList.add('active'));
    gsap.to('#first-green-screen svg', {opacity:0, duration:.5, delay:2});
    gsap.fromTo(
        '#first-green-screen',
        { opacity: 1 },
        {
            duration: 3,
            ease: 'power4.inOut',
            opacity: 0,
            display: 'none',
            delay:1.5
        }
    );
});

const H = new Highway.Core({
	renderers: {
		index: BoorpretRenderer,
		cursos: BoorpretRenderer,
		terapias: BoorpretRenderer,
		nosotros: BoorpretRenderer,
		contacto: BoorpretRenderer
	},
	transitions: {
		default: Fade
	}
});


//Funciones utiles
export const checkCssFileIsImported = (page) => {
    let isIncluded = false;
    let linksUrls= [];
    let links = document.querySelectorAll('link[type="text/css"]');
    for(let i=0;i<links.length;i++){
        if(links[i].href.includes(page)){
            isIncluded = true;
            linksUrls.push( links[i] );
        }
    }
    return {isIncluded:isIncluded, links:linksUrls};
}

export const loadCSS = (page) => {
    return new Promise((resolve, reject) => {
        let result = checkCssFileIsImported(page);
        if(!result.isIncluded){
            import(`../pages/${page}/${page}.css`).then(() => {
                resolve()
            }).catch(err => reject(err));
        }else{
            resolve();
        }
    });
}
export const removeCSS = (page) => {
    let result = checkCssFileIsImported(page);
    if(result.isIncluded){
        result.links.forEach(link => {
            link.remove();
        });
    }
}