import gsap from 'gsap';

let allTerapiasAnimations = [];

export function terapiasAnimations() {


	window.addEventListener('load', () => {
		gsap.to(window, { duration: 0.1, scrollTo:{y:0} });
	});
	/*--- Animacion inicial ----*/
	//gsap.fromTo('#therapy2icon, #therapy3icon, #therapy4icon, #therapy5icon, #therapy6icon', { opacity: 0 }, { opacity: 0.4, duration: 2.5, stagger: 0.18 });

	/*----------------------------------------------*/
	/* --------- Therapies parametrizadas--------- */
	/*--------------------------------------------*/
	let objects = [
		{ section: '#therapy1', icon: '#therapy1icon', enterTrigger: '#therapy1', actions: 'play reverse play none' },
		{ section: '#therapy2', icon: '#therapy2icon', enterTrigger: '#therapy1', actions: 'play reverse play reverse' },
		{ section: '#therapy3', icon: '#therapy3icon', enterTrigger: '#therapy2', actions: 'play reverse play reverse' },
		{ section: '#therapy4', icon: '#therapy4icon', enterTrigger: '#therapy3', actions: 'play reverse play reverse' },
		{ section: '#therapy5', icon: '#therapy5icon', enterTrigger: '#therapy4', actions: 'play reverse play reverse' },
		{ section: '#therapy6', icon: '#therapy6icon', enterTrigger: '#therapy5', actions: 'play reverse play reverse' }
	];

	//Animacion de las diferentes terapias
	objects.forEach((object, i) => {
		let anim = gsap.fromTo( object.section,
			{ opacity: 0.1 },
			{
				opacity: 1,
				duration: 0.5,
				onStart: () => {
					gsap.to(object.icon, { opacity: 1, duration: 0.3 });
				},
				scrollTrigger: {
					trigger: object.enterTrigger,
					start: i !== 0 ? 'bottom center' : 'top center+=100',
					endTrigger: object.section,
					end: 'bottom center',
					toggleActions: object.actions,
					onEnter: () => {
						gsap.to(object.section, { y: 0 });
					},
					onLeave: () => {
						gsap.to(object.icon, { opacity: 0.4, duration: 0.3 });
						gsap.to(object.section, { y: -100, ease: 'power2.in' });
					},
					onEnterBack: () => {
						gsap.to(object.section, { y: 0 });
					},
					onLeaveBack: () => {
						gsap.to(object.section, { y: 100, ease: 'power2.out' });
						gsap.to(object.icon, { opacity: 0.4, duration: 0.3 });
					}
				}
			}
		);
		allTerapiasAnimations.push(anim);
	});
}

export function scrollToTherapy(terapiaActiva) {
	document.querySelectorAll('.therapy-icon').forEach((element) => {
		element.addEventListener('click', () => {
			if (window.innerWidth < 800 && window.innerWidth > 500) {
				let isNegative = document.querySelector(element.dataset['section']).style.transform.includes('-100');
				gsap.to(window, { duration: 1, scrollTo: { y: element.dataset['section'], offsetY: window.innerHeight * 0.35 - (isNegative ? 300 : 100) } });
			} else {
				//Desktop & Movil
				let isNegativeResp = document.querySelector(element.dataset['section']).style.transform.includes('-100');
				gsap.to(window, { duration: 1,scrollTo: { y: element.dataset['section'], offsetY:isNegativeResp ? 100 : 300}});
			}
		});
		if(terapiaActiva && element.dataset['section'] === `#${terapiaActiva}`){
			gsap.to(element, {opacity:1, duration:.1});
			element.click();
		}
	});
}


export const killTerapiasANimations = () => {
	allTerapiasAnimations.map((animation, i) => {
		if (animation.scrollTrigger) {
			animation.scrollTrigger.kill();
		}
		animation.kill();
	});
}