import gsap from "gsap";
import axios from "axios";

const validEmail = false;
let allContactoAnimations = [];

export function alertAnimation() {
  document.querySelector(".alert-background").style.display = "flex";
  let alertAnimations = gsap.fromTo(
    ".alert-background",
    { opacity: 0 },
    {
      duration: 0.5,
      opacity: 1,
      onComplete: () => {
        document
          .querySelector(".alert-background")
          .addEventListener("click", function (event) {
            gsap.fromTo(
              ".alert-background",
              { opacity: 1 },
              {
                duration: 0.5,
                opacity: 0,
                onComplete: () => {
                  document.querySelector(".alert-background").style.display =
                    "none";
                },
              }
            );
          });
      },
    }
  );

  allContactoAnimations.push(alertAnimations);
}

export function contactAnimations() {
  var tl = gsap.timeline();
  let aContactTitle = tl.fromTo(
    ".contact-h1",
    { opacity: 0, y: -50 },
    { opacity: 1, duration: 0.8, ease: "power1.inOut", y: 0 },
    "apareciendo"
  );
  let aContactInput = tl.fromTo(
    ".form-input",
    { opacity: 0 },
    { opacity: 1, duration: 0.8, ease: "power1.inOut", stagger: 0.1 }
  );

  let aContactMandala = gsap.to(
    ".mandala-bg",
    { duration: 3, opacity: 0.05 },
    "apareciendo"
  );
  let aContactLManda = gsap.to(".left", {
    duration: 250,
    rotation: "360",
    repeat: -1,
  });
  let aContactRManda = gsap.to(".right", {
    duration: 250,
    rotation: "-360",
    repeat: -1,
  });

  allContactoAnimations = [
    aContactTitle,
    aContactInput,
    aContactMandala,
    aContactLManda,
    aContactRManda,
  ];
}

export function submitForm() {
  document
    .querySelector("#contact-form")
    .addEventListener("submit", function (event) {
      event.preventDefault();
      axios
        .post("https://email.boorpret.com/send", {
          name: document.getElementById("contact-form-name").value,
          email: document.getElementById("contact-form-email").value,
          messageHtml: document.getElementById("contact-form-textarea").value,
          emailTo: "pepeg93@hotmail.com",
        })
        .then(() => {
          cleanForm();
          document.querySelector(".alert-background").style.display = "none";
        })
        .catch((err) => {
          console.error(err);
        });
      alertAnimation();
    });
}

const cleanForm = () => {
  document.getElementById("contact-form-name").value = "";
  document.getElementById("contact-form-email").value = "";
  document.getElementById("contact-form-textarea").value = "";
};

export function formValidation() {
  let name = document.getElementById("contact-form-name");
  let email = document.getElementById("contact-form-email");
  let message = document.getElementById("contact-form-textarea");

  let nameError = document.querySelector(".name");
  let emailError = document.querySelector(".email");
  let messageError = document.querySelector(".message");
  let messageChars = document.querySelector(".character-count");

  name.addEventListener("input", function (event) {
    if (name.value.length > 50 || name.value.length <= 0) {
      nameError.classList.add("error-visible");
    } else {
      nameError.classList.remove("error-visible");
    }
  });
  email.addEventListener("input", function (event) {
    const regexEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let validEmail = regexEmail.test(String(email).toLowerCase());
    if (
      validEmail ||
      email.validity.typeMismatch ||
      email.value.length > 50 ||
      email.value.length <= 0
    ) {
      emailError.classList.add("error-visible");
    } else {
      emailError.classList.remove("error-visible");
    }
  });
  message.addEventListener("input", function (event) {
    if (message.value.length <= 0 || message.value.length > 5000) {
      messageError.classList.add("error-visible");
      messageChars.classList.add("error-visible");
    } else {
      messageError.classList.remove("error-visible");
      messageChars.classList.remove("error-visible");
    }
  });
}

export function countChar() {
  var textArea = document.querySelector("#contact-form-textarea");
  var charCount = document.querySelector(".character-count");

  textArea.addEventListener("input", function (event) {
    charCount.innerHTML = "Caracteres: " + textArea.value.length + " / 5000";
  });
}

export function killContactoAnimations() {
  allContactoAnimations.map((animation, i) => {
    if (animation.scrollTrigger) {
      animation.scrollTrigger.kill();
    }
    animation.kill();
  });
}
