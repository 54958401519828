import Highway from '@dogstudio/highway';
import gsap from 'gsap';

class Fade extends Highway.Transition {
  in({from, to, done}) {
    from.remove();
    gsap.to(window, { duration: 0.1, scrollTo:{y:0} });
    gsap.fromTo(to, {opacity: 0}, {opacity: 1, duration: 1, onComplete: done});
  }
  out({from, done}) {
    gsap.fromTo(from, {opacity: 1}, {opacity: 0, duration: 1, onComplete: done});
  }
}

export default Fade;
