import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
let allCursosAnimations = [];

export function cursosAnimations() {
	/*--- Animaciones para que aparezca todo sobre los cursos. ---*/

	let aScrollCursos = gsap.fromTo('.scroll-container', { opacity: 0 }, { duration: 1, opacity: 1 }, 'aparece');
	let aCursoH1 = gsap.fromTo('.courses-container-h1', { opacity: 0 }, { duration: 1, opacity: 1 }, 'aparece');
	let aCursoImg = gsap.fromTo('.courses-container-img', { opacity: 0, x: -30 }, { duration: 1, opacity: 1, x: 0 }, 'aparece');
	let aCursoTexto = gsap.fromTo('.courses-container-text>p', { opacity: 0 }, { duration: 1, opacity: 1, ease: 'power2.inOut', stagger: 0.1 }, 'aparece');
	if (window.innerWidth >= 800) {
		let aCursoContainerL = gsap.fromTo('.course-choice:nth-child(odd)', { x: -100, opacity: 0 }, { x: 0, scrollTrigger: { trigger: '.courses-choices-container', start: 'top center+=100', scrub: 1, end:'+=100px' }, opacity: 1 });
		let aCursoContainerR = gsap.fromTo('.course-choice:nth-child(even)', { x: 100, opacity: 0 }, { x: 0, scrollTrigger: { trigger: '.courses-choices-container', start: 'top center+=100', scrub: 1, end:'+=100px'  }, opacity: 1 });
		allCursosAnimations.push(aCursoContainerL, aCursoContainerR);
	} else {
		let cursos = gsap.utils.toArray('.course-choice');
		cursos.forEach((curso) => {
			gsap.fromTo(curso, { x: -200, opacity: 0 }, { x: 0, scrollTrigger: { trigger: curso, start: 'top bottom-=200', end: '+=50px', scrub: 1 }, opacity: 1 });
		});

		allCursosAnimations.push(cursos);
	}
	allCursosAnimations = [aScrollCursos, aCursoH1, aCursoImg, aCursoTexto];
}

export function scrollToCursos() {
	document.querySelectorAll('.scroll-circle-cursos').forEach((element) => {
		element.addEventListener('click', () => {
			// document.querySelector('.active').classList.remove('active');
			// element.classList.add('active');
			gsap.to(window, { duration: 1, scrollTo: { y: element.dataset['section'] } });
		});
	});
	//Scroll Triggers
	let sections = ['#section1','#section2'];

	let indicators = document.querySelectorAll('.scroll-circle-cursos');
	
	const cambioIndicador = (section) => {
		try{
			document.querySelector('.scroll-circle-cursos.active').classList.remove('active');
		}catch(e){
			// console.error('no hay indicador activo');
		}
		for(let i=0;i<indicators.length;i++){
			if(indicators[i].dataset['section'] === section){
				indicators[i].classList.add('active');
				break;
			}
		}
	}
	sections.forEach((section,i) => {
		ScrollTrigger.create({
			trigger:section,
			start:'top center',
			onEnter:() => {
				cambioIndicador(section); 
			},
			onLeaveBack:()=>{
				if(i>=0){
					cambioIndicador(sections[i -1]);
				}
			}
		})
	});
}

export function killCursosAnimations() {
	allCursosAnimations.map((animation, i) => {
		if (animation.scrollTrigger) {
			animation.scrollTrigger.kill();
		}
		animation.kill();
	});

}
