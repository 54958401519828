/**********************
**---- LIBRERIAS ----**
***********************/
import acAnimated from '../../utils/textAnimations';
import gsap from 'gsap';
import Plyr from 'plyr';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


//Variable global para insertar todas las animaciones
let allInicioAnimations = [];


//Funcion que inicializa todas las animaciones de inicio
const initAnimations = () => {
	//Animacion del canvas
	let canvasAnimation = initCanvasAnimation();

	//Animacion de la cita inicial
	let quoteAnimations = initQuoteAnimations();

	//Animacion de aboutUs
	let aboutAnimations = initAboutAnimations();

	//Animacion de las terapias
	let terapiasAnimations = initTerapiaAnimations();

	//Animacion del video
	let videoAnimations = initVideoAnimations();

	
	allInicioAnimations = allInicioAnimations.concat(canvasAnimation).concat(quoteAnimations).concat(aboutAnimations).concat(terapiasAnimations).concat(videoAnimations);
}


export const initIndexPage = () => {
	//Inicializo el reproductor;
	const player = new Plyr('#player');

	//Inicializo las animaciones
	initAnimations();
}


//Funcion para la animacion del canvas
const initCanvasAnimation = () => {
	//Obtengo el canvas
	const canvas = document.getElementById('canvas_background');
	const ctx = canvas.getContext('2d');

	//Pongo un contador del progeso a 0
	let progressCanvas = 0;

	/*****
	 * Animación para el canvas. 
	 * 	Inicio: Cuando la seccion dos toca abajo de la pantalla con la parte de arriba.
	 * 	Fin: Cuando la mitad - 100px de la seccion2 toca el alto de la pantalla.
	 * 	Accion: Durante el progreso de la animacion, voy actualziando el valor del progressCanvas
	*/
	let canvasAnimation = ScrollTrigger.create({
		trigger: '#section2',
		start: 'top bottom',
		endTrigger: '#section2',
		end: 'center-=100px top',
		onUpdate: ({ progress }) => {
			progressCanvas = progress;
		}
	});

	//Creo el canvas y lo renderizo en cada frame
	const render = () => {
		canvas.width = window.innerWidth * 1.2;
		canvas.height = window.innerHeight;

		let x1 = progressCanvas * gsap.utils.interpolate(progressCanvas, 3, 0.2);
		let x2 = progressCanvas * gsap.utils.interpolate(progressCanvas, 1, 0.2);
		let fx = progressCanvas * gsap.utils.interpolate(progressCanvas, 1.5, 0.3);

		let cpx1 = canvas.width * Math.min(x1, 1);
		let cpx2 = canvas.width * Math.min(x2, 1);
		let x = canvas.width * Math.min(fx, 1);

		ctx.beginPath();
		ctx.moveTo(0, 0);
		ctx.lineTo(canvas.width * Math.min(1, progressCanvas), 0);
		ctx.bezierCurveTo(cpx1, (canvas.height / 2) * 0.8, cpx2, (canvas.height / 2) * 1.3, x, canvas.height);
		ctx.lineTo(0, canvas.height);
		ctx.closePath();
		ctx.fillStyle = 'white';
		ctx.fill();
		window.requestAnimationFrame(render);
	};

	render();

	return canvasAnimation;
}
//Function para la animacion de la cita inicial
const initQuoteAnimations = () => {
	let aNavbar = gsap.fromTo('#navbar, .scroll-container', { opacity: 0 }, { duration: 0.5, ease: 'power3.in', opacity: 1.8, delay: 1 });
	let aSec1 = gsap.to('#section1', { scrollTrigger: { trigger: '.photo-bg-container', scrub: true, start: 'top top', end: 'bottom' }, duration: 1, delay: 1.8, ease: 'power2.inOut', onComplete: () => {} });
	let aQuote = gsap.fromTo('#quote-text', { opacity: 0 }, { scrollTrigger: { trigger: '#quote-text', end: '.about-us-container' }, duration: 0.5, opacity: 1, delay: 1.8 });
	let aQuoteText = gsap.fromTo(acAnimated.Plugins.SplitText(document.querySelector('.quote-text'), {}).chars, { opacity: 0 }, { duration: 0.7, opacity: 1, stagger: 0.02, delay: 1.8 });
	let aQuoteName = gsap.fromTo('#quote-author', { opacity: 0 }, { scrollTrigger: { trigger: '#quote-text' }, duration: 1, ease: 'power3.inOut', opacity: 1, delay: 4 });

	return [ aNavbar, aSec1, aQuote, aQuoteText, aQuoteName ]
}
//Funcion para la animacion durante el ¿Que es vivirte?
const initAboutAnimations = () => {
	let aboutUsAnimation, aboutUsTextAnimation;
	if (window.innerWidth >= 800) {
		aboutUsTextAnimation = gsap.fromTo('.about-us-text', { opacity: 0 }, { 
			scrollTrigger: {
				trigger: '.about-us-text', 
				start: 'top bottom', 
				scrub: true, 
				endTrigger: '.about-us-text', 
				end: 'bottom bottom' 
			}, opacity: 1 });
		aboutUsAnimation = gsap.to('#section2', { scrollTrigger: { trigger: '.about-us-text', start: 'top top', pin: true,pinSpacing:false,anticipatePin:2, end: '+=800px' } });
	} else {
		let distanceTop = window.innerWidth > 500 ? 30 : 70;
		aboutUsTextAnimation = gsap.fromTo('.about-us-text', { opacity: 0 }, { scrollTrigger: { trigger: '.about-us-text', start: 'top+=100 bottom', scrub: 1, endTrigger: '.about-us-text', end: 'bottom bottom' }, opacity: 1 });
		aboutUsAnimation = gsap.to('#section2', { scrollTrigger: { trigger: '.about-us-text', start: `top-=${distanceTop}px top`, pin: true, end: '+=300px' } });
	}

	let aInicioMandalaEnter = gsap.fromTo('.mandala-bg-inicio', { opacity: 0, x:'60%' }, { opacity: 0.4, scrollTrigger: { trigger: '.about-us-text', start: 'top top', scrub: 1 , toggleActions: 'play none play reverse' } });
	let aInicioMandala = gsap.to('.mandala-bg-inicio', { scrollTrigger: { trigger: '.about-us-text', start: 'top top', scrub: 1, endTrigger: '.video-player-container', end: 'bottom+=2000px bottom' }, rotation: 0 });

	return [ aboutUsAnimation, aboutUsTextAnimation, aInicioMandalaEnter, aInicioMandala ]
}
//Funcion para la animacion de las terapias
const initTerapiaAnimations = () => {
	let terapias = gsap.utils.toArray('.therapy-container');

	let terapiasAnimatons = [];
	terapias.forEach((terapia, index) => {
		if (window.innerWidth >= 800) {
			let terapiasTL = gsap.timeline({
				scrollTrigger: {
					trigger: '#section3',
					start: 'top bottom',
					end: 'bottom bottom',
					scrub: 1
				}
			});
			terapiasTL.fromTo(terapia, { y: index * 100, opacity: 0 }, { y: 0, opacity: 1, duration: 1 });
			terapiasAnimatons.push(terapiasTL);
		} else {
			let terapiasResp = gsap.utils.toArray('.therapy-container');
			terapiasResp.forEach((terapiaResp) => {
				let anim = gsap.fromTo(terapiaResp, { x: 100, opacity: 0 }, { scrollTrigger: { trigger: terapiaResp, start: 'top bottom-=150', scrub: 1, end: '+=100px' }, x: 0, opacity: 1, duration: 6, stagger: 1 });
				terapiasAnimatons.push(anim);
			});
		}
	});

	return terapiasAnimatons;
}
//Funcion para la animacion del video
const initVideoAnimations = () => {
	let aVideoTitle = gsap.fromTo('#video-title', { x: 300, opacity: 0 }, { scrollTrigger: { trigger: '#video-title', start: 'top bottom', scrub: 1, end: '+=50px' }, opacity: 1, x: 0 });
	let aVideoPlayer = gsap.fromTo('.video-player-container', { x: -300, opacity: 0 }, { scrollTrigger: { trigger: '.video-player-container', start: 'center bottom', scrub: 1, end: '+=100px' }, opacity: 1, x: 0 });

	return [ aVideoTitle, aVideoPlayer ]
}


//Funcion para el control del scroll con los botones laterales
export function scrollToInicio() {

	//Evento para cuando clickas en los botones
	document.querySelectorAll('.scroll-circle').forEach((element) => {
		element.addEventListener('click', () => {
			//document.querySelector('.active').classList.remove('active');
			//element.classList.add('active');
			gsap.to(window, { duration: 1, scrollTo: { y: element.dataset['section'] } });
		});
	});

	//Scroll Triggers
	let sections = ['#section1','#section2','#section3','#section4'];

	let indicators = document.querySelectorAll('.scroll-circle');
	const cambioIndicador = (section) => {
		if(document.querySelector('.scroll-circle.active')){
			document.querySelector('.scroll-circle.active').classList.remove('active');
		}
		for(let i=0;i<indicators.length;i++){
			if(indicators[i].dataset['section'] === section){
				indicators[i].classList.add('active');
				break;
			}
		}
	}
	sections.forEach((section,i) => {
		ScrollTrigger.create({
			trigger:section,
			start:'top center',
			onEnter:() => {
				cambioIndicador(section); 
			},
			onLeaveBack:()=>{
				if(i>=0){
					cambioIndicador(sections[i -1]);
				}
			}
		})
	});
}

//Funcion para matar todas las animaciones
export function killInicioAnimations() {
	allInicioAnimations.map((animation, i) => {
		if (animation.scrollTrigger) {
			animation.scrollTrigger.kill();
		}
		animation.kill();
	});
}
