var acAnimated = {Plugins: {}};
/* SplitText Plugin - Starts */
acAnimated.Plugins.SplitText = function(element, options) {
    if (!options.hasOwnProperty("words")) options.words = 1;
    if (!options.hasOwnProperty("chars")) options.chars = 1;
    if (!options.hasOwnProperty("spacing")) options.spacing = 5;
    this.searchTextNodes = function(element) {
        let foundTextNodes = [];
        if (!element) return foundTextNodes;
        for (let i=0; i<=element.childNodes.length-1; i++) {
            let node = element.childNodes[i];
            if (node.nodeName === "#text") {
                if(!!node.nodeValue.trim())
                    foundTextNodes.push(node);
            }else{
                foundTextNodes = foundTextNodes.concat(this.searchTextNodes(node));
            }
        }
        return foundTextNodes;
    };
    this.createElement = function(text, relatedNode) {
        let node = document.createElement("div");
        let nodeText = document.createTextNode(text.trim());
        node.nodeText = nodeText;
        node.appendChild(nodeText);
        node.style.display = "inline-block";
        node.style.position = "relative";
        if (text.trim() === "") node.style.width = String(options.spacing) + "px";
        relatedNode.parentNode.insertBefore(node, relatedNode);
        return node;
    };

    this.splitCharacters = function(textNode) {
        let characters = textNode.nodeValue.toString();
        let chars = [];
        if (characters.trim() !== "") {
            for (let c=0; c<=characters.length-1; c++) {
                let character = characters.substr(c, 1)
                let char = this.createElement(character, textNode);
                if (character.trim() !== "") chars.push(char);
            }
            textNode.parentNode.removeChild(textNode);
        }
        return chars;
    };

    this.splitWords = function(textNode) {
        let textWords = textNode.nodeValue.toString().split(" ");
        let words = [];
        for (let w=0; w<=textWords.length-1; w++) {
            let textWord = textWords[w];
            let word = this.createElement(textWord, textNode);
            if (textWord.trim() !== "") words.push(word);
            if (w < textWords.length-1) this.createElement(" ", textNode); //spacing for word
        }
        textNode.parentNode.removeChild(textNode);
        return words;
    };

    this.splitTextNodes = function(textNodes) {
        var splitText = {words: [], chars: []};
        for (var i=0; i<=textNodes.length-1; i++) {
            var textNode = textNodes[i];
            if (options.words == 0) {
                splitText.chars = splitText.chars.concat(this.splitCharacters(textNode));
            } else {
                var words = this.splitWords(textNode);
                if (options.chars == 1) {
                    for (var w=0; w<=words.length-1; w++) {
                        var word = words[w];
                        var chars = this.splitCharacters(word.nodeText);
                        splitText.chars = splitText.chars.concat(chars);
                        word.chars = chars;
                    }
                }
                splitText.words = splitText.words.concat(words);
            }
        }
        return splitText;
    }
    const textNodes = this.searchTextNodes(element);
    const splitText = this.splitTextNodes(textNodes);
    //const splitText = {chars:"", words:""};
    return splitText;
}

export default acAnimated;