/**********************
 **---- LIBRERIAS ----**
 ***********************/
import gsap from "gsap";

/**********************
 **---- IMAGENES ----**
 ***********************/
import menuImagen from "../assets/menu-green.svg";
import menuWhite from "../assets/menu-white.svg";
import mandalaImagen from "../assets/mandala.svg";

function initNavbar() {
  const navbarDiv = document.getElementById("navbar");

  var template = `
  <div id="navbarDiv" class="navbar">
    <header>
      <a class="corner-logo-img" href="/index.html"></a>
	  <img class="navbarButton" src="${menuImagen}"/>
    </header>
	   <div class="fullscreen-menu"  style="visibility:hidden;">
					<a class="link" data-page="index" href='/index.html'>Inicio</a>
					<a class="link" data-page="terapias" href="/terapias.html">Terapias</a>
					<a class="link" data-page="nosotros" href="/nosotros.html">Nosotros</a>
          			<a class="link" data-page="cursos" href="/cursos.html">Cursos</a>
					<a class="link" data-page="contacto" href="/contacto.html">Contacto</a>
					<div id="canvas-wrapper" >
						<canvas id="canvas-menu" ></canvas>
					</div>
          <div class="background-menu">
            <img class="mandala-menu" src="${mandalaImagen}"/>
          </div>
	    </div>
</div>
  `;
  navbarDiv.insertAdjacentHTML("afterbegin", template);

  function drawCanvas() {
    var canvas = document.getElementById("canvas-menu");
    var ctx = canvas.getContext("2d");

    canvas.width = Math.max(window.innerWidth, window.innerHeight);
    canvas.height = window.innerHeight;

    function draw(delta) {
      canvas.width = canvas.width;
      ctx.fillStyle = "#87cb7e";

      var randomLeft = Math.abs(Math.pow(Math.sin(delta / 2000), 2)) * 100;
      var randomRight =
        Math.abs(Math.pow(Math.sin(delta / 2000 + 10), 2)) * 100;
      var randomLeftConstraint =
        Math.abs(Math.pow(Math.sin(delta / 2000 + 2), 2)) * 100;
      var randomRightConstraint =
        Math.abs(Math.pow(Math.sin(delta / 2000 + 1), 2)) * 100;

      ctx.beginPath();
      ctx.moveTo(0, randomLeft);

      ctx.bezierCurveTo(
        canvas.width / 3,
        randomLeftConstraint,
        (canvas.width / 3) * 2,
        randomRightConstraint,
        canvas.width,
        randomRight
      );
      ctx.lineTo(canvas.width, canvas.height);
      ctx.lineTo(0, canvas.height);
      ctx.lineTo(0, randomLeft);

      ctx.closePath();
      ctx.fill();
      window.requestAnimationFrame(draw);
    }
    window.requestAnimationFrame(draw);
  }

  function toggleDropdown() {
    let dropdownVisible =
      navbarDiv.querySelector(".fullscreen-menu").style.visibility;

    if (dropdownVisible === "hidden") {
      navbarDiv.querySelector(".fullscreen-menu").style.visibility = "visible";
      navbarDiv.querySelector(".navbarButton").src = menuWhite;
      drawCanvas();
      rotateMandala();
      document.querySelector("html").style.overflow = "hidden";
      var fullscreenmenu = navbarDiv.querySelector(".fullscreen-menu");
      gsap.fromTo(
        fullscreenmenu,
        { opacity: 0 },
        { duration: 1, ease: "power3.inOut", opacity: 1 }
      );
    } else {
      document.querySelector("html").style.overflow = "unset";
      var fullscreenmenu = navbarDiv.querySelector(".fullscreen-menu");
      gsap.fromTo(
        fullscreenmenu,
        { opacity: 1 },
        {
          duration: 1,
          ease: "power3.inOut",
          opacity: 0,
          onStart: () => {
            navbarDiv.querySelector(".navbarButton").src = menuImagen;
          },
          onComplete: () => {
            navbarDiv.querySelector(".fullscreen-menu").style.visibility =
              "hidden";
          },
        }
      );
    }

    let links = document.querySelectorAll(".link");

    links.forEach((link) => {
      link.addEventListener("click", (event) => {
        navbarDiv.querySelector(".navbarButton").src = menuImagen;
        navbarDiv.querySelector(".fullscreen-menu").style.visibility = "hidden";
        document.querySelector("html").style.overflow = "unset";
      });
    });
  }

  function rotateMandala() {
    var mandala = navbarDiv.querySelector(".mandala-menu");
    gsap.to(mandala, { duration: 150, rotation: "360", repeat: -1 });
  }

  //Conected Callback (para añadir funciones)

  navbarDiv
    .querySelector(".navbarButton")
    .addEventListener("click", () => toggleDropdown());
}

export default initNavbar;
